<i18n>
{
  "en": {
    "step": "Step",
    "of": "of",
    "developed": "Developed by",
    "wepipe-forms": "wepipe forms"
  },
  "pt-BR": {
    "step": "Etapa",
    "of": "de",
    "developed": "Desenvolvido por",
    "wepipe-forms": "wepipe forms"
  }
}
</i18n>

<template>
  <div id="form_page" :style="customStyle">
    <div class="content">
      <div class="logo">
        <img
          v-if="hasValue(form.brand)"
          :src="form.brand"
          width="195"
          height="60"
          :alt="domain"
        />
      </div>
      <h1 v-if="hasValue(form.title) && step < 3">
        {{ form.title }}
      </h1>
      <h2 v-if="hasValue(form.description) && step < 3">
        {{ form.description }}
      </h2>
      <customer-step
        v-if="step === 0"
        @nextStep="createLead"
        :loading="btnLoading"
      />
      <custom-fields-step
        v-if="step === CUSTOM_FIELDS_STEP"
        @nextStep="updateCustomFields"
        :loading="btnLoading"
      />
      <address-step
        v-if="step === ADDRESS_STEP"
        @nextStep="updateContact"
        :loading="btnLoading"
      />
      <finished v-if="step === CONGRATULATIONS_STEP" @again="resetForm" />
      <div class="step" v-if="step != CONGRATULATIONS_STEP">
        {{ $t("step") }} {{ stepNumber }} {{ $t("of") }} {{ lastStepNumber }}
      </div>
      <div class="by">
        {{ $t("developed") }}
        <a :href="link" target="_blank">{{ $t("wepipe-forms") }}</a>
      </div>
    </div>
    <we-loading-overflow :loading="loading" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CustomerStep from "@/views/CustomerStep.vue";
import CustomFieldsStep from "@/views/CustomFieldsStep.vue";
import AddressStep from "@/views/AddressStep.vue";
import Finished from "@/views/Finished.vue";
import Vue from "vue";
import VueAnalytics from "vue-analytics";
import router from "../router";

export default {
  components: {
    CustomerStep,
    CustomFieldsStep,
    AddressStep,
    Finished
  },
  data() {
    return {
      step: 0,
      stepNumber: 1,
      loading: true,
      response: {},
      btnLoading: false,
      CUSTOM_FIELDS_STEP: 1,
      ADDRESS_STEP: 2,
      CONGRATULATIONS_STEP: 3
    };
  },
  metaInfo() {
    return {
      title: this.form.metatag_title,
      description: this.form.description
    };
  },
  computed: {
    customStyle() {
      return {
        backgroundColor: `#${this.form.background_color}`
      };
    },
    form() {
      return this.$store.getters.getForm;
    },
    customer() {
      return this.$store.getters.getForm.customer || {};
    },
    domain() {
      return this.$store.getters.getDomain;
    },
    formName() {
      return this.$store.getters.getFormName;
    },
    link() {
      return `https://wepipe.com.br/?utm_source=app&utm_medium=form&utm_campaign=wepipe-organic&term=${this.domain}&utm_content=link`;
    },
    customFieldsInputs() {
      return this.$store.getters.getCustomFieldsInputs;
    },
    customFieldsStepEnabled() {
      return (
        this.customFieldsInputs?.length > 0 ||
        this.form?.is_file_upload_enabled ||
        this.form?.is_product_stage_enabled
      );
    },
    addressStepEnabled() {
      return this.form?.is_address_stage_enabled;
    },
    lastStepNumber() {
      let quantity = 1;
      if (this.customFieldsStepEnabled) quantity++;
      if (this.addressStepEnabled) quantity++;

      return quantity;
    }
  },
  methods: {
    ...mapActions([
      "formsRequest",
      "createLeadRequest",
      "updateLeadRequest",
      "productsRequest"
    ]),
    generateSessionId() {
      const todayEpoch = Date.now();
      this.$store.commit("setSessionId", todayEpoch);
    },
    resetForm() {
      this.step = 0;
      this.stepNumber = 1;
      this.$store.commit("resetCustomFields");
    },
    async doRequests() {
      const form = await this.formsRequest();

      if (form.response?.status >= 404) {
        this.$router.push({ name: "NotFound" });
        return;
      }

      if (
        form.response?.data?.error &&
        form.response?.data?.error[0]?.includes("token")
      ) {
        this.$router.push({ name: "PasswordRequired" });
      }

      if (form?.response?.status === 401) {
        this.$router.push({ name: "Unauthorized" });
      }

      if (this.form.favicon && this.form.favicon.trim().length >= 0) {
        const favicon = document.getElementById("favicon");
        favicon.href = this.form.favicon;
      }

      if (this.form.title && this.form.title.trim().length >= 0) {
        document.title = this.form.title;
      }
      if (
        this.form.google_analytics &&
        this.form.google_analytics.trim().length >= 0
      ) {
        Vue.use(VueAnalytics, {
          id: `UA-${this.form.google_analytics}`,
          router
        });
      }
      this.loading = false;
    },
    async createLead(val) {
      this.btnLoading = true;

      const payload = {
        contact_data: { ...val },
        form_data: {
          stage: 1,
          is_last_stage: this.lastStepNumber === this.stepNumber
        },
        deal_data: null,
        form_files: null,
        session_id: this.$store.getters.getSessionId
      };

      const requestResponse = await this.createLeadRequest(payload);
      this.response = requestResponse.data;

      if (requestResponse.status === 200) {
        delete val.form_data;

        if (this.customFieldsStepEnabled) {
          this.step = this.CUSTOM_FIELDS_STEP;
        } else if (this.addressStepEnabled) {
          this.step = this.ADDRESS_STEP;
        } else {
          this.step = this.CONGRATULATIONS_STEP;
        }

        this.stepNumber++;
      }

      this.btnLoading = false;
    },
    async updateCustomFields({ customFields, products }) {
      this.btnLoading = true;

      if (Object.keys(customFields).length > 0) {
        this.response.deal_data.custom_fields = [customFields];
      }

      if (products.length > 0) {
        this.response.deal_data.products = products?.map(e => {
          return {
            id: e.id,
            amount: e.amount
          };
        });
      }

      this.response.form_data = {
        stage: 2,
        is_last_stage: this.lastStepNumber === this.stepNumber
      };
      const requestResponse = await this.updateLeadRequest(this.response);
      if (requestResponse.status === 200) {
        this.response.deal_data.custom_fields = [customFields];

        if (this.addressStepEnabled) {
          this.step = this.ADDRESS_STEP;
        } else {
          this.step = this.CONGRATULATIONS_STEP;
        }

        this.stepNumber++;
      }

      this.btnLoading = false;
    },
    async updateContact(val) {
      this.btnLoading = true;

      this.response.contact_data = { ...this.response.contact_data, ...val };
      this.response.form_data = {
        stage: 3,
        is_last_stage: this.lastStepNumber === this.stepNumber
      };

      const requestResponse = await this.updateLeadRequest(this.response);
      if (requestResponse.status === 200) {
        this.response.contact_data = {
          ...requestResponse.data.contact_data,
          ...val
        };
        this.step++;
        this.stepNumber++;
      }

      this.btnLoading = false;
    },
    hasValue(str) {
      return str && str.trim().length > 0;
    }
  },
  async mounted() {
    if (
      this.domain != this.$route.params.domain ||
      this.formName != this.$route.params.formName
    ) {
      localStorage.removeItem("we-form-token");
      sessionStorage.removeItem("we-form-token");
      this.$store.commit("setDomain", this.$route.params.domain);
      this.$store.commit("setFormName", this.$route.params.formName);
    }

    this.generateSessionId();

    await this.doRequests();

    if (this.form.is_product_stage_enabled) {
      this.productsRequest();
    }
  }
};
</script>

<style lang="scss">
#form_page {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  padding: 85px;
  display: flex;
  justify-content: center;
  background: var(--background-2);

  @media (max-width: 800px) {
    padding: 0;
  }

  .content {
    background: var(--background-1);
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 50px;
    padding-bottom: 20px;
    margin: auto 0;
    z-index: 1;
    position: relative;
    width: 100%;
    max-width: 600px;

    @media (max-width: 800px) {
      border-radius: 0;
      max-width: 100%;
      padding: 40px 15px;
      padding-top: 30px;
      padding-bottom: 20px;
      height: fit-content;
      min-height: 100%;
      box-shadow: none;
    }

    .bottom {
      margin-top: 24px;
    }

    .logo {
      margin: 0 auto;
      margin-bottom: 36px;
      width: fit-content;

      img {
        width: 100%;
        max-width: 195px;
        height: auto;

        @media (max-width: 800px) {
          max-width: 150px;
        }
      }
    }

    h1 {
      font-size: 24px;
      line-height: 36px;
      color: var(--text-1);
      font-weight: $medium;
      margin-bottom: 26px;
      text-align: center;
    }

    h2 {
      font-weight: $regular;
      font-size: 16px;
      line-height: 24px;
      color: var(--text-2);
      margin-bottom: 50px;
      text-align: center;
    }

    .form {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      gap: 20px;
      row-gap: 16px;

      @include sm {
        grid-template-columns: 1fr;
      }

      .double-column {
        grid-column-start: 1;
        grid-column-end: 3;

        @include sm {
          grid-column-start: initial;
          grid-column-end: initial;
        }
      }
    }

    .we-drop-file {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .we-button {
      margin: 0 auto;
      width: 100%;
      max-width: 230px;

      @media (max-width: 800px) {
        max-width: 100%;
      }
    }

    .step {
      margin-top: 8px;
      text-align: center;
      font-size: 14px;
      color: var(--text-2);
    }

    .by {
      font-size: 10px;
      color: var(--text-2);
      font-weight: $medium;
      margin-top: 36px;
      text-align: center;
    }
  }
}
</style>
