<i18n>
{
  "en": {
    "products": "Products",
    "select": "Select",
    "remove": "Remove",
    "total": "Total",
    "next": "Next",
    "file-error-title": "Wasn't possible to go next",
    "file-error-description": "Please check if you've attached some file."
  },
  "pt-BR": {
    "products": "Produtos",
    "select": "Selecione",
    "remove": "Remover",
    "total": "Total",
    "next": "Próximo",
    "file-error-title": "Não foi possível avançar!",
    "file-error-description": "Verifique se anexou um arquivo."
  }
}
</i18n>

<template>
  <div class="custom-fields-step">
    <v-form class="form" ref="customFieldsStep">
      <section
        class="product-fields double-column"
        v-if="form.is_product_stage_enabled"
      >
        <we-input
          type="global-multiselect"
          :label="$t('products')"
          :placeholder="$t('select')"
          :items="productList"
          v-model="productIds"
          @input="changeProducts"
        />
        <div class="product-list">
          <div
            class="product-item"
            v-for="(product, i) in selectedProducts"
            :key="product.id"
          >
            <we-rounded-picture
              :picture="product.picture"
              :name="product.name"
              type="product"
              size="50"
            />
            <div class="product-content">
              <we-text-tooltip class="product-name" :text="product.name" />
              <div class="product-actions">
                <div class="price-quantity">
                  <div class="product-price">
                    {{ product.price | valueToReais }}
                  </div>
                  <div class="quantity-selector">
                    <div class="quantity-button" @click="sub(product)">
                      <font-awesome-icon icon="minus" />
                    </div>
                    <input
                      type="text"
                      v-maska="'###'"
                      v-model="product.amount"
                      @blur="checkAmount($event, product)"
                    />
                    <div class="quantity-button" @click="add(product)">
                      <font-awesome-icon icon="plus" />
                    </div>
                  </div>
                </div>
                <we-icon-button
                  @click="remove(i)"
                  class="small"
                  :icon="['far', 'trash-alt']"
                  :name="$t('remove')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="products-total">
          {{ $t("total") }}: <span>{{ totalPrice }}</span>
        </div>
      </section>
      <we-input
        v-for="(field, i) in customFieldsInputsWithConditions"
        :key="i"
        :class="field.layout"
        :type="field.type"
        :label="field.label"
        :items="field.values"
        :isRequired="field.is_required"
        :placeholder="field.placeholder"
        v-model="customFields[field.name]"
        :mask="field.mask"
        @input="updateFields"
      />
    </v-form>
    <we-drop-file
      v-if="form.is_file_upload_enabled"
      ref="dropFileComponent"
      :recordId="formLead.deals"
      :title="form.file_upload_field_label"
    />
    <div class="bottom double-column">
      <we-button
        :style="`background: ${buttonColor} !important;`"
        @click="send"
        class="success"
        :text="$t('next')"
        icon="chevron-right"
        iconPosition="right"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputs: [],
      productIds: [],
      selectedProducts: []
    };
  },
  computed: {
    totalPrice() {
      let total = 0;

      this.selectedProducts.forEach(e => {
        total += e.price * e.amount;
      });

      return this.$options.filters.valueToReais(total);
    },
    enabledProductsId() {
      return this.form.products.map(e => e.id);
    },
    products() {
      return this.$store.getters.getProducts || [];
    },
    productList() {
      return this.products
        .filter(e => {
          if (!this.enabledProductsId || this.enabledProductsId.length <= 0)
            return true;

          return this.enabledProductsId.includes(e.id);
        })
        .map(e => {
          return {
            text: e.name,
            value: e.id
          };
        });
    },
    buttonColor() {
      return this.form?.schema?.next_button_color;
    },
    customFields() {
      return this.$store.getters.getCustomFields;
    },
    customFieldsInputs() {
      return this.$store.getters.getCustomFieldsInputs;
    },
    customFieldsInputsWithConditions() {
      return this.customFieldsInputs.filter(customField => {
        if (customField.visibility_condition?.length > 0) {
          let conditionsSatified = 0;

          customField.visibility_condition.forEach(condition => {
            if (this.customFields[condition.field_name] === condition.value) {
              conditionsSatified++;
            } else if (Array.isArray(this.customFields[condition.field_name])) {
              if (
                this.customFields[condition.field_name].includes(
                  condition.value
                )
              ) {
                conditionsSatified++;
              }
            }
          });

          return conditionsSatified === customField.visibility_condition.length;
        }

        return true;
      });
    },
    formLead() {
      return this.$store.getters.getFormLead;
    },
    form() {
      return this.$store.getters.getForm;
    },
    files() {
      return this.$store.getters.getFiles;
    }
  },
  methods: {
    remove(productIndex) {
      this.productIds.splice(productIndex, 1);
      this.changeProducts();
    },
    add(product) {
      product.amount++;
    },
    sub(product) {
      if (product.amount > 1) {
        product.amount--;
      }
    },
    checkAmount(e, product) {
      if (e.target.value <= 0) {
        product.amount = 1;
      }
    },
    changeProducts() {
      this.selectedProducts = this.products.filter(e =>
        this.productIds.includes(e.id)
      );
    },
    send() {
      const validated = this.$refs.customFieldsStep.validate();

      if (validated) {
        if (this.form.is_file_upload_required) {
          if (this.files.length > 0) {
            this.$emit("nextStep", {
              customFields: this.customFields,
              products: this.selectedProducts
            });
          } else {
            const snackBar = {
              title: this.$t("file-error-title"),
              description: this.$t("file-error-description"),
              status: "error"
            };

            this.$store.commit("setSnackBar", snackBar);
            this.$store.commit("addSnackBar");
          }
        } else {
          this.$emit("nextStep", {
            customFields: this.customFields,
            products: this.selectedProducts
          });
        }
      }
    },
    updateFields() {
      this._computedWatchers.customFieldsInputsWithConditions.run();
      this.$forceUpdate();
    }
  },
  destroyed() {
    this.$store.commit("setFiles", []);
    this.$store.commit("setFilesToUpload", []);
  }
};
</script>

<style lang="scss">
.product-fields {
  .product-list {
    margin-bottom: 18px;
    .product-item {
      max-width: 400px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 60px minmax(0, 1fr);
      align-items: center;
      gap: 12px;
      margin-bottom: 8px;
      border-radius: 10px;
      padding: 7px 15px;

      .product-picture {
        width: 100%;
        height: 50px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center center;
        }
      }

      .product-content {
        .product-name {
          font-size: 14px;
          font-weight: $medium;
          color: $black;
          margin-bottom: 2px;
        }

        .product-actions {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .price-quantity {
            display: flex;
            align-items: center;
          }
          .quantity-selector {
            display: flex;
            align-items: center;
            pointer-events: all;
            border: 1px solid $line;
            border-radius: 18px;
            height: 36px;
            overflow: hidden;
            margin-left: 16px;

            &:hover {
              border-color: $dark60;
            }

            .quantity-button {
              width: 36px;
              height: 36px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 3px;
              cursor: pointer;

              &:hover {
                background: $primary25;

                svg {
                  color: $primary;
                }
              }

              svg {
                width: 11px;
                height: auto;
                color: $dark60;
              }
            }

            input {
              border: none;
              background: $we-inherit;
              font-size: 16px;
              line-height: 16px;
              font-weight: $semibold;
              color: $dark60;
              width: 31px;
              text-align: center;
              -moz-appearance: textfield;

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              &:focus {
                border: none;
                outline: none !important;
                box-shadow: none !important;
              }
            }
          }

          .product-price {
            color: $we-green;
            font-weight: $medium;
            font-size: 14px;
          }
        }
      }
    }
  }

  .products-total {
    font-size: 18px;
    font-weight: $regular;
    text-align: center;
    color: $dark60;

    span {
      color: $we-green;
      font-weight: $medium;
    }
  }
}
</style>
