<i18n>
{
  "en": {
    "update-photo": "Update photo",
    "remove-photo": "Remove photo"
  },
  "pt-BR": {
    "update-photo": "Atualizar foto",
    "remove-photo": "Remover foto"
  }
}
</i18n>

<template>
  <v-menu
    rounded
    bottom
    left
    offset-y
    content-class="we-profile"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ attrs, on }">
      <div class="menu-btn" v-bind="attrs" v-on="on">
        <slot name="action" />
      </div>
    </template>
    <v-list>
      <div class="top-section">
        <div class="data">
          <div class="picture">
            <we-rounded-picture
              :picture="loggedUser.picture"
              :name="loggedUser.first_name"
              size="80"
            />
            <div class="action">
              <input
                type="file"
                id="upload"
                ref="file"
                class="picture"
                accept="image/x-png, image/gif, image/jpeg"
                @change="onFileChange"
              />
              <we-icon-button
                color="icon-green"
                icon="camera"
                :name="$t('update-photo')"
                @click="selectImage"
              />
              <we-icon-button
                color="icon-red"
                :icon="{ prefix: 'far', iconName: 'trash-alt' }"
                :name="$t('remove-photo')"
                @click="deleteUserImage(loggedUser.id)"
              />
            </div>
            <we-loading-overflow class="small" :loading="imgLoading" />
          </div>
        </div>
        <slot name="profile" />
      </div>
      <div class="bottom-section">
        <slot name="bottom-section" />
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    closeOnClick: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      picture: {},
      imgLoading: false
    };
  },
  methods: {
    ...mapActions([
      "updateLoggedUserPictureRequest",
      "deleteLoggedUserPictureRequest"
    ]),
    selectImage() {
      this.$refs.file.click();
    },
    async onFileChange(e) {
      const file = e.target.files[0];
      this.picture = file;
      this.imgLoading = true;
      await this.updateLoggedUserPictureRequest(this.picture);
      this.imgLoading = false;
    },
    async deleteUserImage(id) {
      this.imgLoading = true;
      await this.deleteLoggedUserPictureRequest(id);
      this.imgLoading = false;
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters?.getLoggedUser;
    }
  }
};
</script>

<style lang="scss" scoped></style>
