<i18n>
{
  "en": {
    "zip": "Zip Code",
    "street": "Street",
    "number": "Number",
    "district": "District",
    "note": "Note",
    "city": "City",
    "state": "State",
    "next": "Next",
    "optional": "Optional"
  },
  "pt-BR": {
    "zip": "CEP",
    "street": "Rua",
    "number": "Número",
    "district": "Bairro",
    "note": "Complemento",
    "city": "Cidade",
    "state": "Estado",
    "next": "Próximo",
    "optional": "Opcional"
  }
}
</i18n>

<template>
  <div class="address-step">
    <v-form class="form" ref="addressStep" lazy-validation @submit.prevent>
      <we-input
        v-model="payload.address_zipcode"
        type="text"
        :label="`${$t('zip')}${requiredText}`"
        :mask="$root.$t('zipcode-mask')"
        :isRequired="form.is_address_required"
        @blur="getCep($event)"
      />
      <we-input
        v-model="payload.address_street"
        type="text"
        :label="`${$t('street')}${requiredText}`"
        :isRequired="form.is_address_required"
        :loading="cepLoading"
      />
      <we-input
        v-model="payload.address_number"
        :mask="'######'"
        type="text"
        :label="`${$t('number')}${requiredText}`"
        :isRequired="form.is_address_required"
      />
      <we-input
        v-model="payload.address_block"
        type="text"
        :label="`${$t('district')}${requiredText}`"
        :loading="cepLoading"
      />
      <we-input
        class="double-column"
        v-model="payload.address_note"
        type="text"
        :label="$t('note')"
      />
      <we-input
        v-model="payload.address_city"
        type="text"
        :label="`${$t('city')}${requiredText}`"
        :isRequired="form.is_address_required"
        :loading="cepLoading"
      />
      <we-input
        v-model="payload.address_state"
        type="text"
        :label="`${$t('state')}${requiredText}`"
        :isRequired="form.is_address_required"
        :loading="cepLoading"
      />
    </v-form>
    <div class="bottom double-column">
      <we-button
        :style="`background: ${buttonColor} !important;`"
        @click="send"
        class="success"
        :text="$t('next')"
        icon="chevron-right"
        iconPosition="right"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cepLoading: false,
      payload: {
        address_zipcode: "",
        address_street: "",
        address_number: "",
        address_block: "",
        address_note: "",
        address_city: "",
        address_state: ""
      }
    };
  },
  computed: {
    buttonColor() {
      return this.form?.schema?.next_button_color;
    },
    formLead() {
      return this.$store.getters.getFormLead;
    },
    form() {
      return this.$store.getters.getForm;
    },
    requiredText() {
      return this.form.is_address_required ? "" : ` (${this.$t("optional")})`;
    }
  },
  methods: {
    ...mapActions(["cepRequest"]),
    send() {
      const validated = this.$refs.addressStep.validate();

      if (validated) {
        this.$emit("nextStep", this.payload);
      }
    },
    async getCep(number) {
      if (this.$i18n.locale != "pt-BR") return;

      if (number.length === 9) {
        this.cepLoading = true;
        const response = await this.cepRequest(number.split("-").join(""));

        if (response.data) {
          this.payload.address_street = response.data.logradouro;
          this.payload.address_block = response.data.bairro;
          this.payload.address_city = response.data.localidade;
          this.payload.address_state = response.data.uf;
        }
        this.cepLoading = false;
      }
    }
  },
  mounted() {
    if (this.formLead?.contact?.address_zipcode?.length > 0) {
      this.payload = { ...this.formLead.contact };
    }
  }
};
</script>

<style></style>
