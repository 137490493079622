<i18n>
{
  "en": {
    "email-error-1": "Email required",
    "email-error-2": "Insert a valid email",
    "name-error-1": "Full name required",
    "name-error-2": "Insert a valid full name",
    "required": "Field required",
    "type-for-create": "Type for create an item",
    "press": "Press",
    "for-create": "for create item",
    "custom-field-not-found": "We didn't found any custom field",
    "custom-field-create": "Create field",
    "select-group": "Select group",
    "select-date": "Select date",
    "select-time": "Select time"
  },
  "pt-BR": {
    "email-error-1": "Email obrigatório",
    "email-error-2": "Insira um email válido",
    "name-error-1": "Nome completo obrigatório",
    "name-error-2": "Insira um nome completo válido",
    "required": "Campo obrigatório",
    "type-for-create": "Digite para criar um item",
    "press": "Aperte",
    "for-create": "para criar o item",
    "custom-field-not-found": "Não encontramos nenhum campo customizado de oportunidade",
    "custom-field-create": "Criar campo",
    "select-group": "Selecionar grupo",
    "select-date": "Selecione a data",
    "select-time": "Selecione o horário"
  }
}
</i18n>

<template>
  <div class="we-input" :class="type" v-bind="$attrs">
    <div v-if="loading" class="we-input-loading">
      <v-progress-circular
        indeterminate
        color="primary"
        size="18"
        width="3"
      ></v-progress-circular>
    </div>
    <div v-if="label && !['link', 'div'].includes(type)" class="we-input-label">
      <span
        class="label-text"
        :class="{
          link: isUrl
        }"
        @click="openExternalLink"
      >
        {{ label }}
        <font-awesome-icon icon="link" />
      </span>
      <we-icon-tooltip :icon="['far', 'question-circle']" v-if="tooltip">
        <template #text>
          {{ tooltip }}
        </template>
      </we-icon-tooltip>
    </div>
    <div class="field">
      <v-text-field
        v-if="type === 'text'"
        v-maska="mask"
        class="we-input-field"
        :rules="inputRules"
        :hide-details="hideDetails"
        color="primary"
        outlined
        dense
        :hint="hint"
        :counter="counter"
        :maxlength="maxlength"
        :prefix="prefix"
        :suffix="suffix"
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :is-required="isRequired"
        @blur="onBlur($event)"
        @focus="onFocus"
        @input="onInput"
      />
      <v-text-field
        v-if="type === 'number'"
        type="number"
        class="we-input-field"
        :rules="inputRules"
        :hide-details="hideDetails"
        color="primary"
        outlined
        dense
        :min="min"
        :max="max"
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :is-required="isRequired"
        @blur="onBlur($event)"
        @focus="onFocus"
        @input="onInput"
      />
      <v-text-field
        v-if="type === 'email'"
        class="we-input-field"
        :rules="emailRules"
        :hide-details="hideDetails"
        color="primary"
        outlined
        dense
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :is-required="isRequired"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      />
      <v-text-field
        v-if="type === 'full-name'"
        class="we-input-field"
        :rules="fullNameRules"
        :hide-details="hideDetails"
        color="primary"
        outlined
        dense
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :is-required="isRequired"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      />
      <v-text-field
        v-if="type === 'password'"
        class="we-input-field"
        type="password"
        :rules="inputRules"
        :hide-details="hideDetails"
        color="primary"
        outlined
        dense
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :is-required="isRequired"
        @blur="onBlur($event)"
        @focus="onFocus"
        @input="onInput"
      />
      <v-text-field
        v-if="type === 'search'"
        class="we-input-field search"
        :label="inputLabel"
        :rules="inputRules"
        :hide-details="hideDetails"
        color="primary"
        outlined
        dense
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :is-required="isRequired"
        @blur="onBlur($event)"
        @focus="onFocus"
        @input="onInput"
      >
        <font-awesome-icon slot="append" icon="search" />
      </v-text-field>
      <v-textarea
        v-if="type === 'textarea'"
        class="we-input-field"
        :hide-details="hideDetails"
        auto-grow
        outlined
        :rows="2"
        :row-height="20"
        :hint="hint"
        :counter="counter"
        :maxlength="maxlength"
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="inputRules"
        :is-required="isRequired"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      ></v-textarea>
      <v-select
        v-if="type === 'select'"
        class="we-input-field"
        :hide-details="hideDetails"
        :menu-props="{ nudgeBottom: 42 }"
        outlined
        dense
        :clearable="clearable"
        nudge-top="40"
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="inputRules"
        :is-required="isRequired"
        :items="items"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      />
      <v-autocomplete
        v-if="type === 'select-search'"
        class="we-input-field"
        :hide-details="hideDetails"
        :menu-props="{ contentClass: 'select-search' }"
        outlined
        dense
        :clearable="clearable"
        nudge-top="40"
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="inputRules"
        :is-required="isRequired"
        :items="items"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      />
      <v-autocomplete
        v-if="type === 'pagination'"
        class="we-input-field pagination"
        :menu-props="{ contentClass: 'pagination' }"
        :hide-details="hideDetails"
        outlined
        dense
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="inputRules"
        :is-required="isRequired"
        :items="items"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      />
      <v-combobox
        v-if="type === 'tag'"
        class="we-input-field tags"
        :menu-props="{ contentClass: 'tags' }"
        :hide-no-data="!search"
        :hide-details="hideDetails"
        outlined
        dense
        :clearable="clearable"
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="inputRules"
        :hint="hint"
        :is-required="isRequired"
        :items="[{ header: $t('type-for-create') }, ...items]"
        :search-input.sync="search"
        @change="search = ''"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      >
        <template #no-data>
          <div class="no-data">
            {{ $t("press") }} <kbd>enter</kbd> {{ $t("for-create") }}
            <strong>{{ search }}</strong>
          </div>
        </template>
        <template #selection="{ item, index }">
          <template v-if="index === 0">
            <span class="first-selected">
              <span class="selected-name">
                {{ item }}
              </span>
            </span>
          </template>
          <span class="number" v-if="index === 1">
            +{{ value.length - 1 }}
          </span>
        </template>
      </v-combobox>
      <v-combobox
        v-if="type === 'tags'"
        class="we-input-field tags"
        :menu-props="{ contentClass: 'tags' }"
        :hide-no-data="!search"
        :hide-details="hideDetails"
        outlined
        dense
        multiple
        :clearable="clearable"
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="inputRules"
        :hint="hint"
        :is-required="isRequired"
        :items="[{ header: $t('type-for-create') }, ...items]"
        :search-input.sync="search"
        @change="search = ''"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      >
        <template #no-data>
          <div class="no-data">
            {{ $t("press") }} <kbd>enter</kbd> {{ $t("for-create") }}
            <strong>{{ search }}</strong>
          </div>
        </template>
        <template #selection="{ item, index }">
          <template v-if="index === 0">
            <span class="first-selected">
              <span class="selected-name">
                {{ item }}
              </span>
            </span>
          </template>
          <span class="number" v-if="index === 1">
            +{{ value.length - 1 }}
          </span>
        </template>
      </v-combobox>
      <v-autocomplete
        v-if="type === 'task'"
        class="we-input-field task"
        :menu-props="{ contentClass: 'task' }"
        :hide-details="hideDetails"
        outlined
        dense
        item-text="name"
        item-value="id"
        return-object
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="inputRules"
        :is-required="isRequired"
        :items="items"
        @update:search-input="$emit('search-input', $event)"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      >
        <template #selection="data">
          <span class="first-selected">
            <div class="task-color" :class="data.item.color"></div>
            <span class="selected-name">
              {{ data.item.name }}
            </span>
          </span>
        </template>
        <template #item="data">
          <div class="task-color" :class="data.item.color"></div>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-autocomplete
        v-if="type === 'color'"
        class="we-input-field task"
        :menu-props="{ contentClass: 'task' }"
        :hide-details="hideDetails"
        outlined
        dense
        item-text="name"
        item-value="color"
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="inputRules"
        :is-required="isRequired"
        :items="items"
        @update:search-input="$emit('search-input', $event)"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      >
        <template #selection="data">
          <div class="task-color" :class="data.item.color"></div>
          <span class="selected-name">
            {{ data.item.name }}
          </span>
        </template>
        <template #item="data">
          <div class="task-color" :class="data.item.color"></div>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-autocomplete
        v-if="type === 'global-select'"
        class="we-input-field global-select"
        :menu-props="{ contentClass: 'global-select' }"
        :hide-details="hideDetails"
        outlined
        dense
        item-text="text"
        item-value="value"
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="inputRules"
        :is-required="isRequired"
        :items="items"
        @update:search-input="$emit('search-input', $event)"
        @change="onChange"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      />
      <v-autocomplete
        v-if="type === 'global-multiselect'"
        class="we-input-field global-multiselect"
        :menu-props="{ contentClass: 'global-multiselect' }"
        :hide-details="hideDetails"
        outlined
        dense
        multiple
        item-text="text"
        item-value="value"
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="inputRules"
        :is-required="isRequired"
        :items="items"
        @update:search-input="$emit('search-input', $event)"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      >
        <template #selection="{ item, index }">
          <span v-if="index === 0" class="first-selected">
            <span class="selected-name">
              {{ item.text }}
            </span>
          </span>
          <span v-if="index === 1" class="number">
            +{{ value.length - 1 }}
          </span>
        </template>
      </v-autocomplete>
      <v-select
        v-if="type === 'multiselect'"
        class="we-input-field"
        :hide-details="hideDetails"
        :menu-props="{ nudgeBottom: 42 }"
        multiple
        outlined
        dense
        :clearable="clearable"
        nudge-bottom="40"
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="inputRules"
        :is-required="isRequired"
        :items="items"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      >
        <template #selection="{ item, index }">
          <template v-if="index === 0">
            <span class="first-selected">
              <span class="selected-name">
                {{ item }}
              </span>
            </span>
          </template>
          <span class="number" v-if="index === 1">
            +{{ value.length - 1 }}
          </span>
        </template>
      </v-select>
      <v-autocomplete
        v-if="type === 'custom-fields'"
        class="we-input-field"
        :hide-details="hideDetails"
        :menu-props="{ contentClass: 'custom-fields' }"
        multiple
        outlined
        dense
        item-text="name"
        item-value="id"
        :clearable="clearable"
        nudge-bottom="40"
        :value="value"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="inputRules"
        :is-required="isRequired"
        :items="groupedItems"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      >
        <template #selection="{ item, index }">
          <span v-if="index === 0" class="first-selected">
            <span class="selected-name">
              {{ item.name }}
            </span>
          </span>
          <span v-if="index === 1" class="number">
            +{{ value.length - 1 }}
          </span>
        </template>
        <template #item="data">
          <v-list-item
            v-if="data.item.isGroup"
            ripple
            @mousedown.prevent
            @click="toggleGroup(data.item.group)"
            link
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-else
            link
            ripple
            @click="toggleCustomField(data.item.id)"
            :class="{
              'primary--text': value.includes(data.item.id),
              'v-list-item--active': value.includes(data.item.id)
            }"
          >
            <v-list-item-action>
              <v-checkbox
                :class="{ 'primary--text': value.includes(data.item.id) }"
                :input-value="value.includes(data.item.id)"
                disabled
              />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template #no-data>
          <div class="no-data-message">
            {{ $t("custom-field-not-found") }}
          </div>
          <we-button
            :text="$t('custom-field-create')"
            class="success centered"
            icon="plus"
            @click="
              $router.push({
                name: 'createCustomField',
                query: { entity: 'deal' }
              })
            "
          />
        </template>
      </v-autocomplete>
      <v-menu
        v-if="type === 'date'"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="0"
        :nudge-bottom="hideDetails ? 0 : -26"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template #activator="{ on }">
          <v-text-field
            :hide-details="hideDetails"
            color="primary"
            class="we-input-field datepicker"
            :placeholder="$t('select-date')"
            autocomplete="off"
            outlined
            dense
            v-maska="$root.$t('date-mask')"
            :clearable="clearable"
            :value="dateValue"
            :is-required="isRequired"
            @click:clear="onDatePickerInput(null)"
            @blur="onDateBlur"
            @focus="onFocus"
            v-on="on"
          >
            <font-awesome-icon slot="append" icon="calendar-days" />
          </v-text-field>
        </template>
        <v-date-picker
          :locale="$i18n.locale"
          :max="maxDate"
          :min="minDate"
          :value="defaultDateValue"
          @input="onDatePickerInput"
        ></v-date-picker>
      </v-menu>
      <v-menu
        v-if="type === 'time'"
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="0"
        :nudge-bottom="hideDetails ? 0 : -26"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template #activator="{ on }">
          <v-text-field
            :hide-details="hideDetails"
            color="primary"
            class="we-input-field datepicker"
            :placeholder="$t('select-time')"
            autocomplete="off"
            outlined
            dense
            v-maska="'##:##'"
            :clearable="clearable"
            :value="timeValue"
            :is-required="isRequired"
            @click:clear="onTimePickerInput(null)"
            @blur="onTimeBlur"
            @input="onInput"
            @focus="onFocus"
            v-on="on"
          >
            <font-awesome-icon
              slot="append"
              :icon="{ prefix: 'far', iconName: 'clock' }"
            />
          </v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          :value="timeValue"
          full-width
          format="24hr"
          @input="onTimePickerInput"
          @click:minute="$refs.menu2.save(timeValue)"
        ></v-time-picker>
      </v-menu>
      <vuetify-money
        v-if="type === 'money'"
        :options="moneyOptions"
        class="we-input-field money"
        :rules="inputRules"
        :hide-details="hideDetails"
        color="primary"
        outlined
        dense
        :value="moneyValue"
        :placeholder="placeholder"
        :readonly="readonly"
        :is-required="isRequired"
        @blur="onBlur($event)"
        @focus="onFocus"
        @input="onMoneyInput"
      />
      <a
        :href="formattedUrl"
        target="_blank"
        rel="noopener"
        class="we-input-link"
        v-if="type === 'link'"
      >
        <font-awesome-icon icon="link" />
        {{ placeholder }}
      </a>
      <div class="we-input-div" v-if="type === 'div'">
        {{ placeholder }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WeInput",
  inheritAttrs: false,
  props: {
    tooltipName: String,
    label: String,
    tooltip: String,
    inputLabel: String,
    value: {
      type: [String, Number, Array, Object],
      default: "",
      description: "Input value"
    },
    type: {
      type: String,
      required: true
    },
    hint: String,
    counter: String,
    maxlength: String,
    prefix: String,
    suffix: String,
    mask: [String, Array],
    max: [String, Number],
    min: [String, Number],
    maxDate: String,
    minDate: String,
    placeholder: String,
    items: Array,
    hideDetails: {
      type: [String, Boolean],
      default: "auto"
    },
    rules: {
      type: Array,
      default: () => []
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    canLoadMore: {
      type: Boolean,
      default: false
    },
    searchLoading: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      time: null,
      search: null,
      menu: false,
      menu2: false,
      componentValue: "",
      moneyDefaultValue: 0,
      moneyOptions: {
        locale: this.$i18n.locale,
        prefix: this.$i18n.locale === "en" ? "$" : "R$",
        suffix: "",
        length: 11,
        precision: 2
      }
    };
  },
  computed: {
    groupedItems() {
      if (this.items.length <= 0) return [];
      let groupName = null;
      let itemsWithGroups = [];
      let groups = [];

      [...this.items]
        .map(e => {
          if (!groups.includes(e.group)) groups.push(e.group);

          return {
            name: e.label,
            id: e.id,
            group: e.group || "",
            isGroup: false
          };
        })
        .sort((a, b) => (a.group > b.group ? 1 : -1))
        .forEach((e, i) => {
          if (e.group !== "" && groupName !== e.group) {
            itemsWithGroups.push({
              name: `${this.$t("select-group")}: ${e.group}`,
              id: i + 999999,
              group: e.group || "",
              isGroup: true
            });

            groupName = e.group;
          }

          itemsWithGroups.push(e);
        });

      return itemsWithGroups;
    },
    isUrl() {
      return (
        !["link", "div"].includes(this.type) &&
        this.$options.filters.isUrl(this.value)
      );
    },
    url() {
      if (this.type === "link" && this.items.length > 0) return this.items[0];

      return "";
    },
    formattedUrl() {
      if (this.url.includes("https://") || this.url.includes("http://"))
        return this.url;

      return `https://${this.url}`;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    },
    fieldName() {
      return this.label ? this.label : this.placeholder;
    },
    emailRules() {
      const rules = [];
      const isValidEmail = v => {
        const isEmail = !v || v?.length <= 0 || /.+@.+\..+/.test(v);
        return isEmail || this.$t("email-error-2");
      };

      rules.push(isValidEmail);

      if (this.isRequired) {
        const rule = v => !!v || this.$t("email-error-1");

        rules.push(rule);
      }

      return rules;
    },
    fullNameRules() {
      return this.isRequired
        ? [
            v => !!v || this.$t("name-error-1"),
            v =>
              /^[a-zA-Z\u00C0-\u017F]+(?:\s[a-zA-Z\u00C0-\u017F]+)+\s*$/.test(
                v
              ) || this.$t("name-error-2")
          ]
        : [];
    },
    requiredRules() {
      return this.isRequired ? [v => !!v || this.$t("required")] : [];
    },
    inputRules() {
      return [...this.requiredRules, ...this.rules];
    },
    moneyValue() {
      return Number(this.value) > 0
        ? Number(this.value)
        : this.moneyDefaultValue;
    },
    timeValue() {
      return this.value?.length > 0 ? this.value : this.componentValue;
    },
    defaultDateValue() {
      return this.value?.length > 0 ? this.value : this.componentValue;
    },
    dateValue() {
      return this.formatDate(this.defaultDateValue);
    }
  },
  methods: {
    toggleCustomField(id) {
      if (this.value.includes(id)) {
        const indexToRemove = this.value.findIndex(e => e === id);

        const valueAux = [...this.value];
        valueAux.splice(indexToRemove, 1);

        this.onInput([...valueAux]);

        return;
      }

      this.onInput([...this.value, id]);
    },
    toggleGroup(group) {
      const ids = this.items
        .filter(e => {
          if (e.isGroup) return null;

          return group === e.group;
        })
        .map(e => e.id);

      const currentAndNewIds = [...this.value, ...ids];
      const uniqueIds = [...new Set(currentAndNewIds)];

      this.onInput(uniqueIds);
    },
    openExternalLink() {
      if (!this.isUrl) return;

      window.open(this.value, "_blank");
    },
    formatDate(val) {
      let formated = val;

      if (val?.length > 0 && this.$i18n.locale === "pt-BR") {
        const date = new Date(val);
        date.setDate(date.getDate() + 1);
        formated = date.toLocaleString("pt-BR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        });
      }

      return formated;
    },
    onWaypoint({ going, direction }) {
      this.$emit("loadMore", { going, direction });
    },
    onChange(val) {
      this.$emit("change", val);
    },
    onInput(evt) {
      this.menu = false;
      this.$emit("input", evt);
    },
    onFocus() {
      this.focused = true;
      this.$emit("focus");
    },
    onBlur(val) {
      this.$emit("blur", val.target.value);
      this.focused = false;
    },
    onDatePickerInput(evt) {
      this.componentValue = evt;
      this.$emit("input", evt);
    },
    onTimePickerInput(evt) {
      this.componentValue = evt;
      this.$emit("input", evt);
    },
    onMoneyInput(evt) {
      if (evt.length > 0) {
        this.moneyDefaultValue = evt;
        this.$emit("input", evt);
      }
    },
    onDateBlur(val) {
      if (val.target.value.length > 0) {
        const brazilianDate = val.target.value.includes("/");
        let date = new Date();

        if (brazilianDate) {
          date = new Date(
            val.target.value
              .split("/")
              .reverse()
              .join("-")
          );
          date.setDate(date.getDate() + 1);
        } else {
          date = new Date(val.target.value);
        }

        const year = date.getFullYear();
        const isValid = d => {
          return d instanceof Date && !isNaN(d);
        };

        const today = new Date();
        let evt = today;

        if (isValid(date) && year > 1900) {
          evt = date;
        }

        const formattedDate = brazilianDate
          ? evt.toLocaleString("fr-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit"
            })
          : this.$options.filters.isoDate(evt).split("T")[0];

        this.onDatePickerInput(formattedDate);
      }
    },
    onTimeBlur(val) {
      if (val.target.value.length > 0) {
        const hour = val.target.value;

        let evt = "12:00";

        if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(hour)) {
          evt = hour;
        }

        this.onTimePickerInput(evt);
      }
    }
  }
};
</script>

<style lang="scss">
.we-input {
  position: relative;

  &.pagination {
    .we-input-label {
      padding-left: 0;
    }
  }

  &.link,
  &.div {
    display: flex;
    align-items: center;
  }

  .we-input-link {
    min-height: 38px;
    display: flex;
    align-items: center;
    color: var(--primary);
    font-size: 14px;
    line-height: 16px;
    font-weight: $medium;

    &:hover {
      color: var(--primary-hover);
    }

    svg {
      width: 14px;
      height: auto;
      margin-right: 8px;
    }
  }

  .we-input-div {
    min-height: 34px;
    display: flex;
    align-items: flex-end;
    padding: 8px 0;
    margin-bottom: 8px;
    color: var(--text-2);
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    font-weight: $medium;
    border-bottom: 1px solid var(--line);
  }

  .we-input-loading {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 38px;
    display: flex;
    top: 15px;
    justify-content: center;
    align-items: center;
    background: rgba(var(--background-1-rgb), 0.5);
    border-radius: 7px;
  }

  .we-input-label {
    font-size: 12px;
    font-weight: $medium;
    color: var(--text-2);
    margin-bottom: 3px;
    padding-left: 13px;
    text-align: left;
    line-height: 1;
    letter-spacing: initial;
    display: flex;
    align-items: center;

    .label-text {
      &.link {
        color: var(--primary);
        cursor: pointer;

        &:hover {
          color: var(--primary-hover);
        }

        svg {
          display: initial;
        }
      }

      svg {
        display: none;
        font-size: 11px;
      }
    }
  }

  .field {
    width: 100%;
    position: relative;

    .v-select {
      color: var(--text-1);

      &.task {
        .first-selected {
          width: calc(100% - 4px);
        }
      }

      .we-product-icon {
        margin-right: 8px;
      }

      .task-color {
        min-width: 4px;
        height: 22px;
        margin-right: 12px;
        border-radius: 4px;
      }

      .first-selected {
        width: calc(100% - 42px);
        min-height: 30px;
        height: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        color: var(--text-1);

        &.only-one {
          width: 100%;
        }
      }

      .number {
        height: 18px;
        background: var(--primary);
        border-radius: 5px;
        color: var(--white);
        font-size: 12px;
        font-weight: $semibold;
        padding: 0 7px;
        display: flex;
        align-items: center;
        margin-left: 5px;
      }
    }
  }
}
</style>
