<i18n>
{
  "en": {
    "integration-completed-title": "Integration completed!",
    "integration-completed-description": "The integration was completed with success.",
    "developed": "Developed by"
  },
  "pt-BR": {
    "integration-completed-title": "Integração concluída!",
    "integration-completed-description": "A integração foi concluída com sucesso.",
    "developed": "Desenvolvido por"
  }
}
</i18n>

<template>
  <div id="integrated" class="content">
    <h1>
      {{ $t("integration-completed-title") }}
    </h1>
    <h2>
      {{ $t("integration-completed-description") }}
    </h2>
    <img
      class="form-picture"
      src="@/assets/undraw/integration.svg"
      alt="Formulário enviado"
      width="325"
      height="222"
    />
    <div class="by">
      {{ $t("developed") }}<a :href="link" target="_blank"> wepipe</a> forms
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.form.metatag_title,
      description: this.form.description
    };
  },
  computed: {
    form() {
      return this.$store.getters.getForm;
    },
    customer() {
      return this.$store.getters.getForm.customer || {};
    },
    domain() {
      return this.$store.getters.getDomain || "default";
    },
    link() {
      return `https://wepipe.com.br/?utm_source=app&utm_medium=form&utm_campaign=wepipe-organic&term=${this.domain}&utm_content=integrated-link`;
    }
  },
  methods: {
    hasValue(str) {
      return str && str.trim().length > 0;
    }
  }
};
</script>
