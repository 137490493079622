import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "access-form-error": "Wasn't possible to access the form!"
  },
  "pt-BR": {
    "access-form-error": "Não foi possível acessar o formulário!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    authRequest({ commit, getters }, payload) {
      const domain = getters.getDomain;
      const formName = getters.getFormName;
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/webforms/${domain}/${formName}/auth`, {
            password: payload.password
          })
          .then(resp => {
            const token = resp.data.token;

            if (payload.savePassword) {
              localStorage.setItem("we-form-token", token);
            }
            sessionStorage.setItem("we-form-token", token);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("access-form-error")
            };

            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
