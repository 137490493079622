<template>
  <div id="message">
    <router-view />
    <div
      class="background"
      :style="{
        background: `#${form.background_color}` || '#FAFBFE'
      }"
    ></div>
  </div>
</template>

<script>
export default {
  computed: {
    form() {
      return this.$store.getters.getForm;
    }
  }
};
</script>

<style lang="scss">
#message {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  padding: 85px;
  display: flex;
  justify-content: center;

  @media (max-width: 800px) {
    padding: 0;
  }

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    background: $background;
    opacity: 0.5;

    svg {
      width: 100%;
      position: absolute;
      bottom: 0;
      display: none;
    }
  }

  .content {
    background: $white;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 50px;
    padding-bottom: 20px;
    margin: auto 0;
    z-index: 1;
    position: relative;
    width: 100%;
    max-width: 502px;

    @media (max-width: 800px) {
      border-radius: 0;
      max-width: 100%;
      padding: 40px 15px;
      padding-top: 30px;
      padding-bottom: 20px;
      height: fit-content;
      min-height: 100%;
      box-shadow: none;
    }

    .logo {
      margin: 0 auto;
      margin-bottom: 36px;
      width: fit-content;

      img {
        width: 100%;
        max-width: 195px;
        height: auto;

        @media (max-width: 800px) {
          max-width: 150px;
        }
      }
    }

    h1 {
      font-size: 24px;
      line-height: 36px;
      color: $black;
      font-weight: $medium;
      margin-bottom: 26px;
      text-align: center;
    }

    h2 {
      font-weight: $regular;
      font-size: 16px;
      line-height: 24px;
      color: $dark60;
      margin-bottom: 50px;
      text-align: center;
    }

    .form {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      gap: 20px;
      row-gap: 0;

      @media (max-width: 576px) {
        display: block;
      }
    }

    .we-drop-file {
      margin-bottom: 24px;
    }

    .we-button {
      margin: 0 auto;
      width: 100%;
      max-width: 230px;

      @media (max-width: 800px) {
        max-width: 100%;
      }
    }

    .step {
      margin-top: 8px;
      text-align: center;
      font-size: 14px;
      color: $dark60;
    }

    .by {
      font-size: 10px;
      color: $dark60;
      font-weight: $medium;
      margin-top: 36px;
      text-align: center;
    }

    .form-picture {
      margin: 0 auto;
      margin-bottom: 80px;
      max-width: 100%;
      display: block;
    }
  }
}
</style>
