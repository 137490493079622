import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "send-form-error": "Wasn't possible to send the form!"
  },
  "pt-BR": {
    "send-form-error": "Não foi possível enviar o formulário!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    domain: "",
    formName: "",
    form: {},
    formLead: {},
    customFieldsInputs: [],
    customFields: {},
    sessionId: 0
  },
  getters: {
    getDomain: state => state.domain || localStorage.getItem("we-form-domain"),
    getFormName: state =>
      state.formName || localStorage.getItem("we-form-name"),
    getForm: state => state.form,
    getFormLead: state => state.formLead,
    getCustomFieldsInputs: state => state.customFieldsInputs,
    getCustomFields: state => state.customFields,
    getSessionId: state => state.sessionId
  },
  mutations: {
    setDomain: (state, payload) => {
      localStorage.setItem("we-form-domain", payload);
      state.domain = payload;
    },
    setFormName: (state, payload) => {
      localStorage.setItem("we-form-name", payload);
      state.formName = payload;
    },
    setForm: (state, payload) => {
      state.form = payload;
    },
    setFormLead: (state, payload) => {
      state.formLead = payload;
    },
    setCustomFieldsInputs: (state, payload) => {
      if (payload?.length <= 0) return;

      const format = payload.map((e, i) => {
        e.key = i;

        if (!e.is_required) {
          e.label = `${e.label} (opcional)`;
        }

        return e;
      });

      state.customFieldsInputs = format;
    },
    setCustomFields: (state, payload) => {
      if (payload?.length <= 0) return;

      payload.forEach(e => {
        state.customFields[e.name] = "";
      });
    },
    resetCustomFields: state => {
      for (var key in state.customFields) {
        state.customFields[key] = "";
      }
    },
    setSessionId: (state, payload) => {
      state.sessionId = payload;
    }
  },
  actions: {
    formsRequest({ commit, getters }) {
      const domain = getters.getDomain;
      const formName = getters.getFormName;

      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/webforms/${domain}/${formName}`)
          .then(resp => {
            const form = resp.data;

            commit("setCustomFieldsInputs", form.custom_fields);
            commit("setCustomFields", form.custom_fields);

            commit("setForm", form);
            resolve(resp);
          })
          .catch(err => {
            resolve(err);
          });
      });
    },
    createLeadRequest({ commit, getters }, payload) {
      const domain = getters.getDomain;
      const formName = getters.getFormName;

      payload.query = getters.getQueryParams;

      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/webforms/${domain}/${formName}/leads`, payload)
          .then(resp => {
            const lead = resp.data;
            commit("setFormLead", lead);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("send-form-error")
            };

            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    },
    updateLeadRequest({ commit, getters }, payload) {
      const domain = getters.getDomain;
      const formName = getters.getFormName;
      const filesUploadedIds = getters.getFiles.map(e => e.id);

      payload.form_files = filesUploadedIds;
      payload.query = getters.getQueryParams;

      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/webforms/${domain}/${formName}/leads`, payload)
          .then(resp => {
            const lead = resp.data;
            commit("setFormLead", lead);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("send-form-error")
            };

            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
