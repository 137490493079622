<i18n>
{
  "en": {
    "code": "Request code",
    "copy": "Copy",
    "copied": "Copied",
    "form-sent": "Your request was sent with success!",
    "fill-again": "Fill again",
    "alt": "Form sent"
  },
  "pt-BR": {
    "code": "Código da solicitação",
    "copy": "Copiar",
    "copied": "Copiado",
    "form-sent": "Formulário enviado com sucesso!",
    "fill-again": "Preencher novamente",
    "alt": "Formulário enviado"
  }
}
</i18n>

<template>
  <div class="final-step">
    <div
      class="finish-text"
      :class="{ 'no-text': !hasFinishText }"
      v-html="finishText"
    ></div>
    <template v-if="form.is_code_label_enabled">
      <div class="deal-label">{{ $t("code") }}:</div>
      <div class="deal-code">
        <span class="code">
          {{ code }}
        </span>
        <v-tooltip v-model="show" top>
          <template v-slot:activator="{ on, attrs }">
            <we-action
              v-bind="attrs"
              v-on="on"
              :text="$t('copy')"
              @click="copyToClipboard"
            />
          </template>
          <span>{{ $t("copied") }}!</span>
        </v-tooltip>
      </div>
    </template>
    <img
      src="@/assets/undraw/form-sent.svg"
      :alt="$t('alt')"
      width="325"
      height="222"
    />
    <we-button
      :style="`background: ${buttonColor} !important;`"
      class="primary"
      :text="$t('fill-again')"
      @click="$emit('again')"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false
    };
  },
  computed: {
    buttonColor() {
      return this.form?.schema?.reset_button_color;
    },
    form() {
      return this.$store.getters.getForm;
    },
    formLead() {
      return this.$store.getters.getFormLead;
    },
    code() {
      return this.formLead?.deal_data?.code || "0";
    },
    hasFinishText() {
      if (!this.form?.thank_you_text) return false;
      if (this.form.thank_you_text.trim() === "<p></p>") return false;

      return true;
    },
    finishText() {
      return this.hasFinishText
        ? this.form.thank_you_text
        : this.$t("form-sent");
    }
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.code);
      this.show = true;

      setTimeout(() => {
        this.show = false;
      }, 2000);
    }
  }
};
</script>

<style lang="scss">
#form_page .content {
  .final-step {
    .finish-text {
      text-align: center;
      color: var(--text-1);
      font-weight: $medium;
      margin-bottom: 18px;

      &.no-text {
        font-size: 18px;
      }

      $default-margin: 1.25rem auto;
      $default-font-size: 1rem;

      & > p img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        border-radius: 7px;
      }

      hr {
        margin-top: -0.875rem;
        border-top: 1px solid var(--text-3);
      }

      ul {
        list-style: initial;
        color: var(--text-1);
        font-size: $default-font-size;
        margin: $default-margin;
      }

      ol {
        list-style: decimal;
        color: var(--text-1);
        font-size: $default-font-size;
        margin: $default-margin;
      }

      ul,
      ol {
        p {
          margin-top: 0;
          margin-bottom: 4px;
          text-align: left;
        }
      }

      code {
        padding: 0;
        font-size: 100%;
      }

      blockquote {
        padding: 0.1px 16px;
        border-left: 5px solid var(--primary);
        margin: $default-margin;
        background: var(--background-1);

        p {
          margin: 1rem 0;
        }
      }

      p {
        color: var(--text-1);
        font-size: $default-font-size;
        margin: $default-margin;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: 2.5rem;
        margin-bottom: 1.25rem;
        font-weight: $semibold;
        color: var(--text-1);
        line-height: initial;
      }

      a {
        font-weight: $semibold;
        word-break: break-word;
      }

      h1 {
        font-size: 2.25rem;
      }

      h2 {
        font-size: 2rem;
      }

      h3 {
        font-size: 1.75rem;
      }

      h4 {
        font-size: 1.5rem;
      }

      h5 {
        font-size: 1.25rem;
      }

      h6 {
        font-size: $default-font-size;
      }

      picture {
        display: block;
        width: 100%;
        margin: 60px 0;

        img {
          width: 100%;
          height: auto;
          border-radius: 7px;
        }
      }
    }

    .deal-label {
      font-size: 13px;
      text-align: center;
      font-weight: $medium;
      color: var(--text-2);
      margin-bottom: 8px;
      text-transform: uppercase;
    }

    .deal-code {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 34px;

      .code {
        font-size: 18px;
        font-weight: $semibold;
        color: var(--text-1);
        margin-right: 6px;
      }
    }

    img {
      width: 100%;
      display: block;
      max-width: 325px;
      height: auto;
      margin: 0 auto;
      margin-bottom: 55px;

      @media (max-width: 800px) {
        max-width: 260px;
      }
    }
  }
}
</style>
