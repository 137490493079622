<i18n>
{
  "en": {
    "previous-page": "Previous page",
    "page": "Page",
    "next-page": "Next page",
    "of": " of "
  },
  "pt-BR": {
    "previous-page": "Página anterior",
    "page": "Página",
    "next-page": "Próxima página",
    "of": " de "
  }
}
</i18n>

<template>
  <div class="we-pagination">
    <we-icon-button
      icon="chevron-left"
      iconSize="small"
      :name="$t('previous-page')"
      @click="prev"
    />
    <we-input
      type="pagination"
      v-model="pages.current_page"
      :items="items"
      placeholder="1"
      @input="emitValue"
      :tooltipName="$t('page')"
    />
    <we-icon-button
      icon="chevron-right"
      iconSize="small"
      :name="$t('next-page')"
      @click="next"
    />
    <div class="size">
      {{ pages.from }}-{{ pages.to }} {{ $t("of") }} {{ pages.total }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pages: {
      type: Object,
      required: true
    },
    storage: String
  },
  computed: {
    items() {
      let number = 1;
      let numberArr = [];

      while (number <= this.pages.last_page) {
        numberArr.push(number);
        number++;
      }

      return numberArr;
    }
  },
  methods: {
    emitValue(val) {
      localStorage.setItem(this.storage, val);
      this.$emit("change", val);
    },
    prev() {
      if (this.pages.current_page > 1) {
        this.pages.current_page--;
        this.emitValue(this.pages.current_page);
      }
    },
    next() {
      if (this.pages.current_page < this.pages.last_page) {
        this.pages.current_page++;
        this.emitValue(this.pages.current_page);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.we-pagination {
  display: flex;
  align-items: center;

  .we-input {
    width: fit-content;
    min-width: 40px;
  }

  .size {
    margin-left: 8px;
    font-size: 14px;
    font-weight: $medium;
    color: $dark60;
  }
}
</style>
