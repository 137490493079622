<template>
  <v-tooltip
    :top="direction === 'top'"
    :right="direction === 'right'"
    :bottom="direction === 'bottom'"
    :left="direction === 'left'"
    :disabled="!name"
  >
    <template v-slot:activator="{ on, attrs }">
      <button
        class="we-icon-button icon"
        @click.prevent="$emit('click')"
        v-bind="attrs"
        v-on="on"
        :class="`${color} ${componentClass}`"
        :bg="bg"
        :iconSize="iconSize"
      >
        <font-awesome-icon :icon="icon" />
      </button>
    </template>
    <span>{{ name }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "we-icon-button",
  props: {
    icon: [String, Object, Array],
    iconSize: String,
    direction: {
      type: String,
      default: "top"
    },
    name: String,
    color: {
      type: String,
      default: ""
    },
    bg: String
  },
  computed: {
    componentClass() {
      if (this.$options._parentVnode.data.staticClass) {
        return this.$options._parentVnode.data.staticClass;
      }

      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: rgba($dark60, 0.1);
  }

  &[iconSize="small"] {
    svg {
      height: 12px;
    }
  }

  &.icon-primary {
    svg {
      color: $primary;
    }
  }

  &.icon-white {
    svg {
      color: $white;
    }
  }

  &.icon-red {
    svg {
      color: $we-red;
    }
  }

  &.icon-green {
    svg {
      color: $we-green;
    }
  }

  &.icon-orange {
    svg {
      color: $we-orange;
    }
  }

  svg {
    pointer-events: none;
    height: 18px;
    width: auto;
    color: $dark60;
  }
}
</style>
