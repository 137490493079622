<i18n>
{
  "en": {
    "title": "Form protected with password",
    "description": "Insert password for start to fill the form.",
    "developed": "Developed by",
    "wepipe-forms": "wepipe forms",
    "password": "Password",
    "save-password": "Save password for next steps",
    "send": "Send"
  },
  "pt-BR": {
    "title": "Formulário protegido com senha",
    "description": "Insira a senha para começar a preencher o formulário.",
    "developed": "Desenvolvido por",
    "wepipe-forms": "wepipe forms",
    "password": "Senha",
    "save-password": "Salvar senha para próximos acessos",
    "send": "Enviar"
  }
}
</i18n>

<template>
  <div id="password_required">
    <div class="content">
      <h1>
        {{ $t("title") }}
      </h1>
      <h2>
        {{ $t("description") }}
      </h2>
      <img
        class="form-picture"
        src="@/assets/undraw/denied.svg"
        :alt="$t('title')"
        width="162"
        height="155"
      />
      <v-form
        class="form"
        ref="customerStep"
        lazy-validation
        @submit.prevent=""
      >
        <we-input
          v-model="payload.password"
          class="double-column"
          type="password"
          :label="$t('password')"
          :hide-details="false"
          isRequired
        />
        <v-switch
          class="double-column"
          :label="$t('save-password')"
          v-model="payload.savePassword"
        />
      </v-form>
      <div class="bottom double-column">
        <we-button
          @click="send"
          class="success"
          :text="$t('send')"
          icon="chevron-right"
          iconPosition="right"
          :loading="loading"
        />
      </div>
      <div class="by">
        {{ $t("developed") }}
        <a :href="link" target="_blank">{{ $t("wepipe-forms") }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      payload: {
        password: "",
        savePassword: false
      }
    };
  },
  methods: {
    ...mapActions(["authRequest"]),
    async send() {
      this.loading = true;
      await this.authRequest(this.payload);
      this.$router.push({
        name: "Domain",
        params: {
          domain: this.domain,
          formName: this.formName
        }
      });
      this.loading = false;
    }
  },
  computed: {
    domain() {
      return this.$store.getters.getDomain || "default";
    },
    formName() {
      return this.$store.getters.getFormName;
    },
    link() {
      return `https://wepipe.com.br/?utm_source=app&utm_medium=form&utm_campaign=wepipe-organic&term=${this.domain}&utm_content=password-link`;
    }
  },
  mounted() {
    localStorage.removeItem("we-form-token");
    sessionStorage.removeItem("we-form-token");
  }
};
</script>

<style lang="scss">
#password_required {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  padding: 85px;
  display: flex;
  justify-content: center;

  @media (max-width: 800px) {
    padding: 0;
  }

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    background: var(--background-1);
    opacity: 0.5;

    svg {
      width: 100%;
      position: absolute;
      bottom: 0;
      display: none;
    }
  }

  .content {
    background: var(--background-1);
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 50px;
    padding-bottom: 20px;
    margin: auto 0;
    z-index: 1;
    position: relative;
    width: 100%;
    max-width: 502px;

    @media (max-width: 800px) {
      border-radius: 0;
      max-width: 100%;
      padding: 40px 15px;
      padding-top: 30px;
      padding-bottom: 20px;
      height: fit-content;
      min-height: 100%;
      box-shadow: none;
    }

    .logo {
      margin: 0 auto;
      margin-bottom: 36px;
      width: fit-content;

      img {
        width: 100%;
        max-width: 195px;
        height: auto;

        @media (max-width: 800px) {
          max-width: 150px;
        }
      }
    }

    h1 {
      font-size: 24px;
      line-height: 36px;
      color: var(--text-1);
      font-weight: $medium;
      margin-bottom: 26px;
      text-align: center;
    }

    h2 {
      font-weight: $regular;
      font-size: 16px;
      line-height: 24px;
      color: var(--text-2);
      margin-bottom: 18px;
      text-align: center;
    }

    .form {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      gap: 20px;
      row-gap: 0;

      .double-column {
        grid-column-start: 1;
        grid-column-end: 3;
      }

      @media (max-width: 576px) {
        display: block;
      }
    }

    .we-drop-file {
      margin-bottom: 24px;
    }

    .we-button {
      margin: 0 auto;
      width: 100%;
      max-width: 230px;

      @media (max-width: 800px) {
        max-width: 100%;
      }
    }

    .step {
      margin-top: 8px;
      text-align: center;
      font-size: 14px;
      color: var(--text-2);
    }

    .by {
      font-size: 10px;
      color: var(--text-2);
      font-weight: $medium;
      margin-top: 36px;
      text-align: center;
    }

    .form-picture {
      margin: 0 auto;
      margin-bottom: 18px;
      max-width: 100%;
      display: block;
    }
  }
}
</style>
