<template>
  <v-tooltip
    :top="direction === 'top'"
    :right="direction === 'right'"
    :bottom="direction === 'bottom'"
    :left="direction === 'left'"
    :disabled="!name"
    :open-delay="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="rounded-picture"
        :class="[type]"
        :style="{
          height: size + 'px',
          minHeight: size + 'px',
          width: size + 'px',
          minWidth: size + 'px'
        }"
        v-bind="attrs"
        v-on="on"
      >
        <img
          v-if="picture"
          :src="picture"
          :alt="name"
          :width="size"
          :height="size"
        />
        <img
          v-else-if="type === 'product'"
          src="@/assets/product.svg"
          :alt="name"
          :width="size"
          :height="size"
        />
        <img
          v-else-if="type === 'company'"
          src="@/assets/company.svg"
          :alt="name"
          :width="size"
          :height="size"
        />
        <img
          v-else
          src="@/assets/profile.svg"
          :alt="name"
          :width="size"
          :height="size"
        />
      </div>
    </template>
    <span>{{ name }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    picture: {
      type: String
    },
    type: String,
    direction: {
      type: String,
      default: "top"
    },
    name: String,
    size: String
  }
};
</script>

<style lang="scss">
.rounded-picture {
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  &.product {
    border-radius: 5px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
</style>
