<template>
  <div class="we-action" @click="$emit('click')">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: String
  }
};
</script>

<style lang="scss">
.we-action {
  height: 23px;
  border-radius: 3px;
  background: $dark8;
  padding: 1px 10px;
  cursor: pointer;
  margin-left: 12px;
  font-size: 14px;
  font-weight: $regular;
  color: $dark60;
  display: flex;
  align-items: center;

  &:hover {
    background: $dark20;
  }

  &.active {
    background: $dark60;
    color: $white;
  }
}
</style>
