<i18n>
{
  "en": {
    "title": "Subscription removed!",
    "description": "Your email was removed with success from our list.",
    "developed": "Developed by",
    "wepipe-forms": "wepipe forms"
  },
  "pt-BR": {
    "title": "Inscrição cancelada!",
    "description": "Seu e-mail foi removido com sucesso da nossa lista.",
    "developed": "Desenvolvido por",
    "wepipe-forms": "wepipe forms"
  }
}
</i18n>

<template>
  <div id="unsubscribed" class="content">
    <h1>
      {{ $t("title") }}
    </h1>
    <h2>
      {{ $t("description") }}
    </h2>
    <img
      class="form-picture"
      src="@/assets/undraw/email-submitted.svg"
      alt="Formulário enviado"
      width="325"
      height="222"
    />
    <div class="by">
      {{ $t("developed") }}
      <a :href="link" target="_blank">{{ $t("wepipe-forms") }}</a>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.form.metatag_title,
      description: this.form.description
    };
  },
  computed: {
    form() {
      return this.$store.getters.getForm;
    },
    customer() {
      return this.$store.getters.getForm.customer || {};
    },
    domain() {
      return this.$store.getters.getDomain || "default";
    },
    link() {
      return `https://wepipe.com.br/?utm_source=app&utm_medium=form&utm_campaign=wepipe-organic&term=${this.domain}&utm_content=unsubscribed-link`;
    }
  },
  methods: {
    hasValue(str) {
      return str && str.trim().length > 0;
    }
  }
};
</script>
