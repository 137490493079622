<template>
  <v-menu rounded bottom left offset-y content-class="we-balloon">
    <template v-slot:activator="{ attrs, on }">
      <div class="menu-btn" v-bind="attrs" v-on="on">
        <slot name="action" />
      </div>
    </template>
    <v-list>
      <v-subheader v-if="title">{{ title }}</v-subheader>
      <slot name="balloon" />
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    closeOnClick: {
      type: Boolean,
      default: true
    },
    title: String
  },
  data() {
    return {
      opened: false
    };
  },
  methods: {
    openBalloon() {
      this.opened = true;
    },
    closeBalloonOnClick() {
      if (this.closeOnClick) {
        this.opened = false;
      }
    },
    closeBalloon() {
      this.opened = false;
    }
  }
};
</script>

<style lang="scss">
.we-balloon {
  background: white;
  &:hover {
    background: white;
  }

  .item {
    max-height: 128px;
    padding: 0 8px;
    align-items: center;
    margin-right: 18px;
    position: relative;
    cursor: pointer;
    background: white;

    &:last-child {
      margin: 0;
    }

    &:hover {
      &::before {
        display: block;
      }
    }
    .balloon-item {
      display: flex;
      &.dropdown-item {
        &:hover {
          background: white;
        }
        &.active {
          color: black;
        }
      }
    }

    &.active {
      .name,
      .number,
      .icon {
        color: $black;
      }
    }
    .icon {
      margin-right: 8px;
      position: relative;
      bottom: -1px;
      color: $dark60;
    }

    .name {
      font-weight: $semibold;
      font-size: 14px;
      color: $dark60;
    }

    .number {
      margin-left: 8px;
      height: 20px;
      display: flex;
      align-items: center;
      padding: 0 6px;
      border-radius: 4px;
      font-size: 13px;
      font-weight: $semibold;
      background: $dark8;
      color: $dark60;
    }
  }
}
</style>
