<i18n>
{
  "en": {
    "input-1": "Full name",
    "input-2": "Phone number",
    "input-3": "Email",
    "agree": "By clicking continue, you agree to our",
    "terms": "Terms & Conditions",
    "next": "Next",
    "terms-required": "You need agree to our Terms & Conditions"
  },
  "pt-BR": {
    "input-1": "Nome completo",
    "input-2": "Número de celular",
    "input-3": "Email",
    "agree": "Declaro que li e estou de acordo com os",
    "terms": "Termos e Condições",
    "next": "Próximo",
    "terms-required": "Você deve concordar com os Termos e Condições"
  }
}
</i18n>

<template>
  <div class="customer-step">
    <v-form class="form" ref="customerStep" lazy-validation @submit.prevent="">
      <we-input
        v-model="fullName"
        type="full-name"
        :label="$t('input-1')"
        isRequired
        @input="setLeadName"
      />
      <we-input
        v-model="payload.phone1"
        type="text"
        :mask="[$root.$t('phone-mask'), $root.$t('phone-second-mask')]"
        :label="$t('input-2')"
        isRequired
      />
      <we-input
        class="double-column"
        v-model="payload.email"
        type="email"
        :label="$t('input-3')"
        isRequired
      />
      <v-switch
        class="double-column"
        v-if="form.is_terms_required"
        required
        :rules="rules"
        v-model="termsAndConditions"
      >
        <template v-slot:label>
          {{ $t("agree") }}
          <a
            @click.stop
            :href="form.terms_of_service"
            target="_blank"
            rel="noopener"
          >
            {{ $t("terms") }}
          </a>
        </template>
      </v-switch>
    </v-form>
    <div class="bottom double-column">
      <we-button
        :style="`background: ${buttonColor} !important;`"
        @click="send"
        class="success"
        :text="$t('next')"
        icon="chevron-right"
        iconPosition="right"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      termsAndConditions: false,
      date: "",
      fullName: "",
      payload: {
        first_name: "",
        last_name: "",
        phone1: "",
        email: ""
      }
    };
  },
  computed: {
    rules() {
      return [this.termsAndConditions || this.$t("terms-required")];
    },
    buttonColor() {
      return this.form?.schema?.next_button_color;
    },
    form() {
      return this.$store.getters.getForm;
    }
  },
  methods: {
    send() {
      const validated = this.$refs.customerStep.validate();

      if (validated) {
        this.$emit("nextStep", this.payload);
      }
    },
    setLeadName(val) {
      const nameArr = val.trim().split(" ");

      if (nameArr.length > 1) {
        this.payload.first_name = nameArr.shift();
        this.payload.last_name = nameArr.join(" ");
      } else {
        this.payload.first_name = val;
      }
    }
  }
};
</script>

<style></style>
