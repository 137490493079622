<i18n>
{
  "en": {
    "sent-at": "Sent at",
    "options": "Options",
    "open": "Open file",
    "delete": "Delete file",
    "drop": "Drop files to upload",
    "description": "Drag and drop here or click to select from computer",
    "upload": "Upload file"
  },
  "pt-BR": {
    "sent-at": "Enviado em",
    "options": "Opções",
    "open": "Visualizar arquivo",
    "delete": "Excluir arquivo",
    "drop": "Solte os arquivos a serem carregados",
    "description": "Arraste aqui ou clique para selecionar do computador",
    "upload": "Anexar arquivo com até 6mb"
  }
}
</i18n>

<template>
  <div class="we-drop-file">
    <input
      type="file"
      multiple
      name="fields[assetsFieldHandle][]"
      id="assetsFieldHandle"
      @change="sendFiles"
      ref="files"
      accept=".pdf,.jpg,.jpeg,.png"
      style="display:none;"
    />
    <div class="file" v-for="(file, i) in files" :key="i">
      <div class="type">
        <img src="@/assets/file.svg" alt="Arquivo" width="37" height="46" />
        <div class="name" ref="fileName">{{ file.name | fileExtension }}</div>
      </div>
      <div class="data">
        <div class="name">
          {{ file.name }}
        </div>
        <div class="size">
          {{ file.size | fileSize }} - {{ $t("sent-at") }}
          {{ file.updated_at | dateTime }}
        </div>
      </div>
      <we-balloon class="dir-left">
        <template #action>
          <we-icon-button icon="ellipsis-h" :name="$t('options')" />
        </template>
        <template #balloon>
          <a :href="file.url" target="_blank" class="balloon-item">
            <font-awesome-icon icon="external-link-alt" />
            {{ $t("open") }}
          </a>
          <div class="balloon-item" @click="removeFile(file.id)">
            <font-awesome-icon
              :icon="{ prefix: 'far', iconName: 'trash-alt' }"
            />
            {{ $t("delete") }}
          </div>
        </template>
      </we-balloon>
    </div>
    <div class="file" v-for="(file, i) in filesToUpload" :key="i + 999999999">
      <div class="type">
        <img src="@/assets/file.svg" alt="Arquivo" width="37" height="46" />
        <div class="name">{{ file.name | fileExtension }}</div>
      </div>
      <div class="data">
        <div class="name">
          {{ file.name }}
        </div>
        <v-progress-linear indeterminate color="success"></v-progress-linear>
      </div>
    </div>
    <div
      class="file"
      v-for="(file, i) in filesWithError"
      :key="i + 99999999999"
    >
      <div class="type">
        <img src="@/assets/file.svg" alt="Arquivo" width="37" height="46" />
        <div class="name">{{ file.name | fileExtension }}</div>
      </div>
      <div class="data">
        <div class="name">
          {{ file.name }}
        </div>
        <v-progress-linear
          color="error"
          rounded
          value="100"
        ></v-progress-linear>
      </div>
      <we-icon-button
        :icon="{ prefix: 'far', iconName: 'trash-alt' }"
        :name="$t('delete')"
        @click="removeFileWithError(i)"
      />
    </div>
    <div
      class="drop-here"
      @click="$refs.files.click()"
      @dragover="dragover"
      :class="{ dragovered }"
    >
      <div class="dragover" @dragleave="dragleave" @drop="drop">
        {{ $t("drop") }}
      </div>
      <div class="we-title">
        {{ getTitle }}
      </div>
      <div class="subtitle">
        {{ $t("description") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    recordId: Array,
    title: String
  },
  data() {
    return {
      dragovered: false
    };
  },
  computed: {
    form() {
      return this.$store.getters.getForm;
    },
    getTitle() {
      return this.title?.length > 0 ? this.title : this.$t("upload");
    },
    files() {
      return this.$store.getters.getFiles;
    },
    filesToUpload() {
      return this.$store.getters.getFilesToUpload;
    },
    filesWithError() {
      return this.$store.getters.getFilesWithError;
    },
    sessionId() {
      return this.$store.getters.getSessionId;
    }
  },
  methods: {
    ...mapActions(["filesRequest", "createFileRequest", "deleteFileRequest"]),
    async allFilesRequest() {
      const payload = {
        record_id: this.recordId
      };

      this.filesRequest(payload);
    },
    async removeFile(fileId) {
      await this.deleteFileRequest(fileId);
      await this.allFilesRequest();
    },
    removeFileWithError(i) {
      this.$store.commit("removeFileWithError", i);
    },
    sendFiles(event) {
      this.onChange(event.target.files);
    },
    onChange(files) {
      let filelist = [...files];
      filelist.map((e, i) => {
        e["id"] = i;
        return e;
      });
      this.$store.commit("setFilesToUpload", filelist);
      filelist.forEach(e => {
        const payload = {
          record_id: this.form.is_lead_mode_enabled
            ? this.recordId
            : [this.sessionId],
          file: e
        };
        this.createFileRequest(payload);
      });
    },
    dragover(e) {
      e.preventDefault();
      this.dragovered = true;
    },
    dragleave(e) {
      e.preventDefault();
      this.dragovered = false;
    },
    drop(event) {
      event.preventDefault();
      this.dragovered = false;
      this.onChange(event.dataTransfer.files);
    }
  }
};
</script>

<style lang="scss">
.file {
  display: grid;
  grid-template-columns: 37px minmax(0, 1fr);
  align-items: center;
  gap: 26px;
  height: 46px;
  margin-bottom: 8px;

  .type {
    position: relative;
    height: 46px;

    .name {
      text-transform: uppercase;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      font-weight: $semibold;
      color: $white;
    }
  }

  .data {
    font-size: 14px;
    font-weight: $regular;
    color: $black;

    .name {
      display: block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      justify-content: space-between;
    }

    .size {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $dark60;
      font-size: 13px;
    }

    .v-progress-linear {
      margin-top: 9px;
    }
  }

  .menu-btn {
    display: none;
  }
}

.drop-here {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: $dark60;
  cursor: pointer;
  border-radius: 5px;
  padding: 15px;
  background: $dark8;
  position: relative;
  text-align: center;

  &.dragovered {
    .dragover {
      display: flex;
    }
  }

  .dragover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    background: $white;
    border-radius: 5px;
    border: 2px dashed $primary;
    font-size: 14px;
    font-weight: $medium;
    color: $primary;
  }

  &:hover {
    background: $dark20;
  }

  .we-title {
    font-size: 14px;
    font-weight: $medium;
  }

  .subtitle {
    font-size: 13px;
    font-weight: $regular;
  }
}
</style>
