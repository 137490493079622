<template>
  <v-tooltip
    :top="direction === 'top'"
    :right="direction === 'right'"
    :bottom="direction === 'bottom'"
    :left="direction === 'left'"
    :disabled="!text"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="we-text-tooltip"
        :class="componentClass"
        v-bind="attrs"
        v-on="on"
        :style="`max-width: ${maxWidth}; -webkit-line-clamp: ${lines};`"
      >
        {{ text }}
      </div>
    </template>
    <span>{{ tooltipText ? tooltipText : text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "we-text-tooltip",
  props: {
    text: [String, Object, Number],
    tooltipText: [String, Object, Number],
    direction: {
      type: String,
      default: "top"
    },
    lines: {
      type: String,
      default: "1"
    },
    maxWidth: String
  },
  computed: {
    componentClass() {
      if (this.$options._parentVnode.data.staticClass) {
        return this.$options._parentVnode.data.staticClass;
      }

      return "";
    }
  }
};
</script>

<style lang="scss">
.we-text-tooltip {
  max-width: fit-content;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
