<template>
  <v-menu rounded bottom left offset-y content-class="we-notification">
    <template v-slot:activator="{ attrs, on }">
      <div class="menu-btn" v-bind="attrs" v-on="on">
        <slot name="action" />
      </div>
    </template>
    <v-list>
      <div class="header">Notificações</div>
      <slot name="notification" />
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    closeOnClick: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped></style>
