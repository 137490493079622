<template>
  <v-app id="app">
    <router-view />
    <we-snack-bar />
  </v-app>
</template>

<script>
import "@/styles/_font.scss";
import "@/styles/_colors.scss";
import "@/styles/_forms.scss";
import "@/styles/_menus.scss";
import "@/styles/_animations.scss";
import WeSnackBar from "@/components/interface/WeSnackBar.vue";

export default {
  components: {
    WeSnackBar
  },
  mounted() {
    if (this.$route.query) {
      this.$store.commit("setQueryParams", this.$route.query);
    }
  }
};
</script>

<style lang="scss">
body,
html {
  font-family: $font;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px rgba(0, 0, 0, 0) inset !important;
  -webkit-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0) inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

.v-application a {
  &:hover {
    color: darken($primary, 30);
  }
}

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

a {
  text-decoration: none;
}

button {
  &:focus {
    outline: 0;
  }
}

$scrollbar-width: 5px;
$scrollbar-padding: 15px;

*::-webkit-scrollbar-track {
  background-color: $dark8;
  opacity: 0;
}

*::-webkit-scrollbar {
  height: (($scrollbar-padding * 2) + $scrollbar-width);
  width: 6px;
  height: 6px;
  background-color: $dark8;
  opacity: 0;
}

*::-webkit-scrollbar-thumb {
  width: 6px;
  height: 6px;
  background-color: $dark20;
  background-clip: padding-box;

  &:hover {
    background-color: $primary;
  }
}

.x-scrollbar {
  *::-webkit-scrollbar-track {
    background-color: $dark8;
    opacity: 0;
  }

  *::-webkit-scrollbar {
    height: (($scrollbar-padding * 2) + $scrollbar-width);
    height: 14px;
    background-color: $dark8;
    opacity: 0;
  }

  *::-webkit-scrollbar-thumb {
    height: 14px;
    background-color: $dark20;
    background-clip: padding-box;

    &:hover {
      background-color: $primary;
    }
  }
}
</style>
