<i18n>
{
  "en": {
    "show-info": "Show information",
    "hide-info": "Hide information",
    "edit-task": "Edit task",
    "delete-task": "Delete task"
  },
  "pt-BR": {
    "show-info": "Mostrar informações",
    "hide-info": "Ocultar informações",
    "edit-task": "Editar tarefa",
    "delete-task": "Excluir tarefa"
  }
}
</i18n>

<template>
  <div class="we-deal-task">
    <div class="main-content">
      <div class="we-title">
        <div class="color" :class="task.task.color"></div>
        <div class="name">
          {{ task.task.name }}
        </div>
      </div>
      <div class="actions">
        <we-icon-button
          v-if="!opened"
          @click="opened = true"
          :icon="{ prefix: 'far', iconName: 'eye' }"
          :name="$t('show-info')"
        />
        <we-icon-button
          v-if="opened"
          @click="opened = false"
          :icon="{ prefix: 'far', iconName: 'eye-slash' }"
          :name="$t('hide-info')"
        />
        <we-balloon class="dir-left">
          <template #action>
            <we-icon-button icon="ellipsis-h" name="Opções" />
          </template>
          <template #balloon>
            <div class="balloon-item" @click="editTask">
              <font-awesome-icon icon="edit" />
              {{ $t("edit-task") }}
            </div>
            <div class="balloon-item" @click="deleteTask">
              <font-awesome-icon
                :icon="{ prefix: 'far', iconName: 'trash-alt' }"
              />
              {{ $t("delete-task") }}
            </div>
          </template>
        </we-balloon>
      </div>
    </div>
    <div class="more-content" v-if="opened">
      <div class="deal-task-item">
        <div class="icon">
          <font-awesome-icon :icon="{ prefix: 'far', iconName: 'clock' }" />
        </div>
        {{ task.start | dealTaskDate }} - {{ task.end | dealTaskHour }}
      </div>
      <div class="deal-task-item">
        <div class="icon">
          <font-awesome-icon icon="check" />
        </div>
        {{ task.status }}
      </div>
      <div class="deal-task-item" v-if="task.note">
        <div class="icon">
          <font-awesome-icon icon="align-left" />
        </div>
        {{ task.note }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    task: Object
  },
  data() {
    return {
      opened: false
    };
  },
  methods: {
    ...mapActions(["dealTasksRequest", "deleteDealTaskRequest"]),
    editTask() {
      if (this.$route.name === "PipelineDeal") {
        this.$router.push({
          name: "EditDealTask",
          params: { taskId: this.task.id }
        });
      } else if (this.$route.name === "TasksDeal") {
        this.$router.push({
          name: "EditDealTaskInTasks",
          params: { taskId: this.task.id }
        });
      } else if (this.$route.name === "Deal") {
        this.$router.push({
          name: "EditDealTaskInDeals",
          params: { taskId: this.task.id }
        });
      } else if (this.$route.name === "ContactDeal") {
        this.$router.push({
          name: "EditDealTaskInContactDeals",
          params: { taskId: this.task.id }
        });
      }
    },
    async deleteTask() {
      await this.deleteDealTaskRequest(this.task.id);
      await this.dealTasksRequest(this.$route.params.dealId);
    }
  }
};
</script>

<style lang="scss">
.we-deal-task {
  .main-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .we-title {
      display: flex;
      align-items: center;

      .color {
        height: 25px;
        width: 4px;
        border-radius: 4px;
        background: $we-green;
        margin-right: 13px;
      }

      .name {
        font-size: 14px;
        color: $black;
        font-weight: $regular;
      }
    }

    .actions {
      display: flex;
      align-items: center;

      .we-balloon .balloon {
        min-width: 180px;
      }
    }
  }

  .more-content {
    padding: 9px 65px 12px 17px;

    .deal-task-item {
      font-size: 14px;
      color: $dark60;
      margin-bottom: 9px;
      display: flex;

      .icon {
        width: 30px;
        min-width: 30px;
        display: flex;
        align-items: center;
        color: $dark60;

        svg {
          height: 16px;
          width: auto;
          color: $dark60;
        }
      }
    }
  }
}
</style>
