import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "products-error": "Wasn't possible to load products!"
  },
  "pt-BR": {
    "products-error": "Não foi possível carregar os produtos!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    products: []
  },
  getters: {
    getProducts: state => state.products
  },
  mutations: {
    setProducts: (state, payload) => {
      state.products = payload;
    }
  },
  actions: {
    productsRequest({ commit, getters }) {
      const domain = getters.getDomain;
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/public/${domain}/products`)
          .then(resp => {
            let products = resp.data || [];

            products = products.map(e => {
              e.amount = 1;
              return e;
            });

            commit("setProducts", products);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("products-error")
            };

            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
