import Vue from "vue";
import WeBalloon from "@/components/interface/WeBalloon.vue";
import WeButton from "@/components/interface/WeButton.vue";
import WePagination from "@/components/interface/WePagination.vue";
import WeIconButton from "@/components/interface/WeIconButton.vue";
import WePictureButton from "@/components/interface/WePictureButton.vue";
import WeRoundedPicture from "@/components/interface/WeRoundedPicture.vue";
import WeInput from "@/components/interface/WeInput.vue";
import WeLoadingOverflow from "@/components/interface/WeLoadingOverflow.vue";
import WeTextTooltip from "@/components/interface/WeTextTooltip.vue";
import WeNavX from "@/components/interface/WeNavX.vue";
import WeDealTask from "@/components/interface/WeDealTask.vue";
import WeShadow from "@/components/interface/WeShadow.vue";
import WeProductIcon from "@/components/interface/WeProductIcon.vue";
import WeAction from "@/components/interface/WeAction.vue";
import WeNotFound from "@/components/interface/WeNotFound.vue";
import WeProfile from "@/components/interface/WeProfile.vue";
import WeNotification from "@/components/interface/WeNotification.vue";
import WeDropFile from "@/components/interface/WeDropFile.vue";

Vue.component("we-icon-button", WeIconButton);
Vue.component("we-picture-button", WePictureButton);
Vue.component("we-rounded-picture", WeRoundedPicture);
Vue.component("we-balloon", WeBalloon);
Vue.component("we-button", WeButton);
Vue.component("we-pagination", WePagination);
Vue.component("we-input", WeInput);
Vue.component("we-loading-overflow", WeLoadingOverflow);
Vue.component("we-text-tooltip", WeTextTooltip);
Vue.component("we-nav-x", WeNavX);
Vue.component("we-deal-task", WeDealTask);
Vue.component("we-shadow", WeShadow);
Vue.component("we-product-icon", WeProductIcon);
Vue.component("we-action", WeAction);
Vue.component("we-not-found", WeNotFound);
Vue.component("we-profile", WeProfile);
Vue.component("we-notification", WeNotification);
Vue.component("we-drop-file", WeDropFile);
