import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import Default from "@/components/layouts/Default.vue";
import Message from "@/components/layouts/Message.vue";
import Form from "@/views/Index.vue";
import Submitted from "@/views/Submitted/Index.vue";
import Integrated from "@/views/Integrated/Index.vue";
import Unsubscribed from "@/views/Unsubscribed/Index.vue";
import Unauthorized from "@/views/Unauthorized/Index.vue";
import PasswordRequired from "@/views/PasswordRequired/Index.vue";
import NotFound from "@/views/NotFound/Index.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Default,
    redirect: "Domain",
    children: [
      {
        path: "/:domain/:formName",
        name: "Domain",
        component: Form
      },
      {
        path: "/password-required",
        name: "PasswordRequired",
        component: PasswordRequired
      },
      {
        path: "/404",
        name: "NotFound",
        component: NotFound
      }
    ]
  },
  {
    path: "/message",
    name: "Message",
    component: Message,
    redirect: "Domain",
    children: [
      {
        path: "/submitted",
        name: "Submitted",
        component: Submitted
      },
      {
        path: "/integrated",
        name: "Integrated",
        component: Integrated
      },
      {
        path: "/unsubscribed",
        name: "Unsubscribed",
        component: Unsubscribed
      },
      {
        path: "/unauthorized",
        name: "Unauthorized",
        component: Unauthorized
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
