<template>
  <div class="we-not-found">
    <div class="not-found-content">
      <div class="we-title">
        <slot name="title" />
      </div>
      <div class="description">
        <slot name="description" />
      </div>
      <div class="picture">
        <slot name="picture" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String
  }
};
</script>

<style lang="scss">
.we-not-found {
  text-align: center;
  padding: 40px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;

  .not-found-content {
    margin: auto 0;
    .we-title {
      font-size: 24px;
      line-height: 30px;
      max-width: 400px;
      margin: 0 auto;
      margin-bottom: 18px;

      b {
        font-weight: $semibold;
      }
    }

    .description {
      color: $dark60;
      font-weight: $medium;
      max-width: 600px;
      margin: 0 auto;
      margin-bottom: 40px;

      b {
        font-weight: $semibold;
      }
    }

    .picture {
      margin: 0 auto;
      max-width: 299px;
      width: 100%;
      height: auto;
    }
  }
}
</style>
