<i18n>
{
  "en": {
    "more": "More"
  },
  "pt-BR": {
    "more": "Mais"
  }
}
</i18n>

<template>
  <nav class="we-nav-x">
    <div class="list">
      <slot name="list" />
      <we-balloon v-if="hasMoreItems" class="balloon-list">
        <template #action>
          <div class="item">
            <div class="name">
              {{ $t("more") }}
            </div>
            <font-awesome-icon class="icon icon-right" icon="chevron-down" />
          </div>
        </template>
        <template #balloon class="balloon-item">
          <slot name="priority-items-list" />
        </template>
      </we-balloon>
    </div>
  </nav>
</template>

<script>
export default {
  props: ["hasMoreItems"]
};
</script>

<style lang="scss">
.we-nav-x {
  display: flex;

  .list {
    display: flex;
    .item {
      height: 36px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      margin-right: 18px;
      position: relative;
      cursor: pointer;

      &:last-child {
        margin: 0;
      }

      &:hover {
        &::before {
          display: block;
        }
      }

      &.active {
        .name,
        .number,
        .icon {
          color: $black;
        }

        &::before {
          display: block;
          background: $we-orange;
        }
      }

      &::before {
        content: "";
        display: none;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        border-radius: 3px;
        width: 100%;
        background: $dark20;
      }

      .icon {
        margin-right: 8px;
        position: relative;
        bottom: -1px;
        color: $dark60;

        &.icon-right {
          margin-right: 0;
          margin-left: 8px;
        }
      }

      .name {
        font-weight: $semibold;
        font-size: 14px;
        color: $dark60;
      }

      .number {
        margin-left: 8px;
        height: 20px;
        display: flex;
        align-items: center;
        padding: 0 6px;
        border-radius: 4px;
        font-size: 13px;
        font-weight: $semibold;
        background: $dark8;
        color: $dark60;
      }
    }
  }
}
</style>
