import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "send-file-error": "Wasn't possible to send the file!",
    "delete-file-error": "Wasn't possible to delete the file!"
  },
  "pt-BR": {
    "send-file-error": "Não foi possível enviar o arquivo!",
    "delete-file-error": "Não foi possível deletar o arquivo!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    files: [],
    filesToUpload: [],
    filesWithError: []
  },
  getters: {
    getFiles: state => state.files,
    getFilesToUpload: state => state.filesToUpload,
    getFilesWithError: state => state.filesWithError
  },
  mutations: {
    setFiles: (state, payload) => {
      state.files = payload;
    },
    setFilesToUpload: (state, payload) => {
      state.filesToUpload = payload;
    },
    uploadFinished: (state, payload) => {
      state.filesToUpload = state.filesToUpload.filter(e => e.id != payload.id);

      state.files = [...state.files, payload.fileUploaded];
    },
    uploadError: (state, payload) => {
      state.filesToUpload = state.filesToUpload.filter(e => e.id != payload.id);

      state.filesWithError = [...state.filesWithError, payload];
    },
    removeFileWithError: (state, payload) => {
      state.filesWithError.splice(payload, 1);
    }
  },
  actions: {
    filesRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/files`, {
            params: {
              ...payload
            }
          })
          .then(resp => {
            const files = resp.data;

            commit("setFiles", files);
            resolve(resp);
          });
      });
    },
    createFileRequest({ commit, getters }, file) {
      let formData = new FormData();
      file.record_id.forEach((id, i) => {
        formData.append(`deals[${i}]`, id);
      });
      formData.append("file", file.file);

      const domain = getters.getDomain;
      const formName = getters.getFormName;

      return new Promise(resolve => {
        Vue.prototype.$http
          .post(`/webforms/${domain}/${formName}/leads/files`, formData)
          .then(resp => {
            const payload = {
              fileUploaded: resp.data,
              id: file.file.id
            };
            commit("uploadFinished", payload);
            resolve(resp);
          })
          .catch(() => {
            const snackBar = {
              title: i18n.t("send-file-error")
            };

            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            commit("uploadError", file.file);
          });
      });
    },
    deleteFileRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .delete(`/files/${payload}`)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("delete-file-error")
            };

            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
