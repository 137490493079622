import Vue from "vue";
import Vuex from "vuex";
import forms from "@/store/modules/forms";
import cep from "@/store/modules/cep";
import auth from "@/store/modules/auth";
import files from "@/store/modules/files";
import snackBar from "@/store/modules/snackBar";
import products from "@/store/modules/products";
import queryParams from "@/store/modules/queryParams";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    forms,
    cep,
    files,
    snackBar,
    products,
    queryParams,
    auth
  }
});
